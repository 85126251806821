import theme from 'theme';
import { makeStyles } from '@material-ui/core/styles';

import {
  green, red, blue, orange,
} from '@material-ui/core/colors';

// todo fix notification colors (may be logo colors)
export default makeStyles({
  success: {
    color: theme.palette.common.white,
    backgroundColor: green[600],
  },
  error: {
    color: theme.palette.common.white,
    backgroundColor: red[600],
  },
  softerror: {
    color: theme.palette.common.white,
    backgroundColor: '#6A2BDC',
  },
  info: {
    color: theme.palette.common.white,
    backgroundColor: blue[500],
  },
  warning: {
    color: theme.palette.common.white,
    backgroundColor: orange[700],
  },
  flexContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  icon: {
    top: theme.spacing(0.25),
    position: 'relative',
    marginRight: theme.spacing(1),
    display: 'inline-block',
  },
  closeIcon: {
    top: theme.spacing(0.05),
    position: 'relative',
    marginRight: theme.spacing(1),
  },
  snackbar: {
    margin: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(3),
  },
  margin: {
    margin: theme.spacing(1),
  },
  message: {
    paddingTop: theme.spacing(0),
    display: 'inline',
  },
});
