import React from 'react';
import {
  func, bool, string, number,
} from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle, faExclamationTriangle,
  faCheckCircle, faInfoCircle,
  faTimes, faBomb,
} from '@fortawesome/pro-light-svg-icons';

import {
  Snackbar, Slide, IconButton,
  SnackbarContent, Typography,
  Box,
  withWidth,
} from '@material-ui/core';

import useStyles from './styles';

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

const Notification = ({
  variant, message, onClose, open, duration, width,
}) => {
  const styles = useStyles();

  const variantIcon = {
    success: <FontAwesomeIcon icon={faCheckCircle} size="2x" className={styles.icon}/>,
    warning: <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className={styles.icon}/>,
    softerror: <FontAwesomeIcon icon={faBomb} size="2x" className={styles.icon}/>,
    error: <FontAwesomeIcon icon={faExclamationCircle} size="2x" className={styles.icon}/>,
    info: <FontAwesomeIcon icon={faInfoCircle} size="2x" className={styles.icon}/>,
  };

  const actions = width !== 'xs' ? [
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={onClose}
    >
      <FontAwesomeIcon icon={faTimes} size="sm" className={styles.closeIcon}/>
    </IconButton>,
  ] : [];

  return (
        <Snackbar
          TransitionComponent={TransitionLeft}
          open={open}
          autoHideDuration={duration}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={onClose}
          message={message}
        >
        <Box
          border={1}
          borderRadius={4}
          >
        <SnackbarContent
          className={styles[variant]}
          aria-describedby="client-snackbar"
          message={
            <div className={styles.flexContainer}>
              {variantIcon[variant]}
              <Typography variant="h6" component="p" id="client-snackbar" className={styles.message}>
                {message}
          </Typography>
          </div>
        }
          action={actions}
        />
        </Box>
        </Snackbar>
  );
};

Notification.propTypes = {
  duration: number.isRequired,
  open: bool.isRequired,
  variant: string.isRequired,
  message: string.isRequired,
  onClose: func.isRequired,
  width: string,
};

export default withWidth()(Notification);
